

<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Scarico del Magazzino"
      :route-father="routeFather"
    >
      <template v-slot:header-actions>
        <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
        <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
        </v-btn>
      </template>

      <template v-slot:toolbar-extension> </template>
      <template v-slot:content>
        <div class="d-flex pa-5" style="width: 100%">
          <AdvancedFilter
            style="width:99%"
            v-model="filtersValue"
            :filters="filterTypes"
            :advance-filters="advanceFilters"
            :external-filter="externalFilter"
            @apply-filters="applyFilters"
          >
            <template v-slot:chip-custom-movements.description="{filter}">
              Descrizione: {{ filter.value }}
            </template>

            <template v-slot:chip-custom-createdAtTime="{ filter }">
              Ora Creazione: {{ filter.value }}
            </template>

            <template v-slot:custom-createdAtTime="{ filter }">
              <TimePicker
                v-model="filter.value"
              ></TimePicker>
            </template>

          </AdvancedFilter>
        </div>
        <div style="overflow-y: hidden" ref="dataTableWrapper">
          <MovementList
            route-father="MovementUnloadList"
            :dataTableHeightValue="dataTableHeightValue"
            :filters="[
              ...filtersValue, 
              { type: 'string', operator: 'equal', field: 'type', value: 'unload' }
            ]"
            :busFilters="busFilters"
          ></MovementList>
        </div>
        <MovementUnloadDialog
          v-if="openMovementUnloadDialog"
          :open-dialog="openMovementUnloadDialog"
          @close="openMovementUnloadDialog = false"
          title="Scarica Prodotti"
        ></MovementUnloadDialog>
      </template>
    </FullScreenDialog>
  </div>
</template>
<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import { SimpleList } from "likablehair-ui-components";
import _movementListFilter from "./filters/movementUnloadList";
import Vue from "vue";
import MovementList from "../movements/MovementList.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import MovementUnloadDialog from "@/components/areas/warehouse/movementUnloads/MovementUnloadDialog.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import TimePicker from '@/components/common/TimePicker.vue';
import movementService from "@/services/warehouse/movements.service.js";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "MovementUnloadList",
  components: {
    MovementList,
    FullScreenDialog,
    SimpleList,
    AdvancedFilter,
    MovementUnloadDialog,
    ExportExcelTable,
    TimePicker
  },
  mixins: [dateUtils],
  data: function () {
    let props = this.$router.resolve({ name: "Warehouse" });

    let dataTableHeightValue = 300
    if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs)
      dataTableHeightValue = 200
    else if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg)
      dataTableHeightValue = 420
    else
      dataTableHeightValue = 1200
    return {
      dialog: true,
      loading: false,
      routeFather: props.href,
      movementListFilter: [],
      busFilters: new Vue(),
      dataTableHeightValue: dataTableHeightValue,
      resizeListener: undefined,
      headers: [],
      rightMenu: [],
      openMovementUnloadDialog: false,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'custom', field: 'createdAtTime', name: 'Ora Creazione', label: 'Ora Creazione', color: "", 
          value: ('0' + (new Date().getHours())).slice(-2) + ':' + ('0' + (new Date().getMinutes())).slice(-2), icon: 'mdi-clock-time-four-outline'},
        { type: 'string', operator: 'like', field: 'movements.code', name: 'Codice Interno', label: 'Codice Interno', color:"", value: undefined },
        { type: 'string', operator: 'like', field: 'im.fileName', name: 'Nome File', label: 'Nome File', color: "", value: undefined, icon: 'mdi-file-document' },
        { type: 'boolean', operator: 'equal', field: 'im.allRowsImported', name: 'Completata', label: 'Compleatata', color: "", value: false },
        { type: 'number', operator: 'equal', field: 'countItems', name: 'Numero Prodotti', label: 'Numero Prodotti', color: "", value: undefined},
        { type: 'number', operator: 'equal', field: 'countItemsImported', name: 'Prodotti Importati', label: 'Prodotti Importati', color: "", value: undefined  },
        { type: 'number', operator: 'equal', field: 'countItemsInError', name: 'Prodotti in Errore', label: 'Prodotti in Errore', color: "", value: undefined }
      ],
      advanceFilters: [
        { type: 'string', operator: 'like', field: 'im.extension', name: 'Estensione File', label: 'Estensione File', color: "", value: undefined },
      ],
      externalFilter: { type: 'custom', operator: 'like', field: 'movements.description', name: 'Descrizione', label: 'Descrizione', color: "", value: "" },
      filtersValue: [
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      isLicenseBlocked: undefined,
    };
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function () {
    this.movementListFilter = _movementListFilter;

    this.filtersValue = this.filters;
    this.applyFilters()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    
    this.fieldsToExport = movementService._fieldsToExport();
    this.worksheetName = "Export Caricamenti Magazzino ";
    this.fileName =
      "Caricamenti_Magazzino_" + this._buildDateToExport(new Date()) +
      ".xls";

    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    goToNew() {
      this.openMovementUnloadDialog = true;
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    applyFilters(filters) {
      this.busFilters.$emit("new-filters", [
        ...this.filtersValue, 
        { type: 'string', operator: 'equal', field: 'type', value: 'unload' }
      ]);
    },
    async fetchExcel(){
      let filters = [...this.filtersValue, { type: 'string', operator: 'equal', field: 'type', value: 'unload' }];

      let movements = (await movementService.list(undefined, 15000, filters)).rows

      movements = movements.map((movement) => {
        if (movement.import)
          return {
            movementId: movement.id,
            importId: movement.importId,
            code: movement.code,
            description: movement.description,
            type: movement.type,
            fileName: movement.import.fileName,
            extension: movement.import.extension,
            createdAtTime: movement.import.createdAt,
            createdAt: movement.import.createdAt,
            countItems: movement.import.items.length,
            countItemsImported: movement.import.itemsImported.length,
            countItemsInError: movement.import.itemsInError.length,
            allRowsImported: movement.import.allRowsImported,
          };
        else
          return {
            movementId: movement.id,
            code: movement.code,
            description: movement.description,
            type: movement.type,
            createdAt: movement.createdAt,
            countItems: movement.items.length,
            countItemsImported: movement.items.length,
            countItemsInError: 0,
            allRowsImported: true,
            createdAtTime: movement.createdAt,
          };
      });

      return movements
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>